import Storage from "@/util/Storage";
import {addLimitProduct, updateLimitProduct} from "@/Api/ShopAdmin";
import {Toast} from "vant";
import { propsData } from "@/views/ShopAdmin/AddLimitTime/model/index"


export function addLimitTime(upData:any={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    const keyArr = Object.keys(upData);
    let userInfo:any = {}
    try{
        userInfo = JSON.parse( Storage.GetData_?.("UserData") )
    }catch (e) {
        console.info("转换失败")
    }
    keyArr.forEach(item=>{
        if ( item == "stock" ){
            if ( upData[item] < 1 ){
                Toast("请添加库存量");
                return Promise.reject();
            }
        }
        if (upData[item] != null && (upData[item]+'').length < 1) {
            Toast(propsData[item])
            return Promise.reject()
        }
    })
    upData.school = userInfo.school || ''
    return (
        Promise.resolve(addLimitProduct({ userId,token },upData).then(res=>{
            if (res.message.code == "200")Toast("添加成功")
            return res.data
        }))
    )
}

export function updateLimitTime(upData:any={},oldData:any={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const keys = Object.keys(upData);
    let num = 0
    keys.forEach(item=>{
        if ( upData[item] != null && ((upData[item]+'').length < 1) || upData[item] != oldData[item] ) num += 1;
    })
    if (num<1){
        Toast("请修改内容")
        return Promise.reject()
    }
    upData.id = oldData.id

    return (
        Promise.resolve(updateLimitProduct({ userId,token },upData).then(res=>{
            if (res.message.code == "200")Toast("修改成功")
            return res.data
        }))
    )
}

